<template >
    <div class="home">
      <v-img
          class="brighten-v-img private"
          src="../assets/home_1280.webp"
          :srcset="`${require('../assets/home_1280.webp')}, ${require('../assets/home_900.webp')} 900w, ${require('../assets/home_400.webp')} 400w`"
      >
        <div class="container">
          <v-col align-content="center" justify="center">
            <div class="row" align-content="center" justify="center">
              <div class="col"></div>
            </div>
            <div class="text-center" >
              <div class=" white--text" style="visibility: visible;">
                <h1 class="">
                  {{ formatContent(true) }} {{formatCityName()?"in "+formatCityName():"" }}
                </h1>
                <h4 class="">
                  Bring a restaurant experience in your own home with a {{ formatContent() }} {{formatCityName()?"in "+formatCityName():"" }}
                </h4>
              </div>

            </div>

<!--            <v-row align-content="center" justify="center">-->
<!--              <v-btn href="search" target="">See Menus</v-btn>-->
<!--            </v-row>-->
          </v-col>
          <v-row align-content="center" justify="center" class="margin-top-bottom-big">
            <v-btn href="/search" target="" @click.prevent="$router.push({path: `/search`})">See Menus</v-btn>
          </v-row>
        </div>
      </v-img>

      <div>
        <v-container fuild class="container margin-top-bottom-big">

          <v-row align-content="center" justify="space-around" class="text-h5" >
              Our {{ formatContent() }} service {{formatCityName()?"in "+formatCityName():"" }} is the perfect option for people who want to enjoy restaurant-quality meals in the comfort of their own home, without having to deal with the hassle of grocery shopping, cooking, serving and cleaning up, leaving you free to relax and enjoy their meal.
          </v-row>

          <v-row align-content="center" justify="space-around"  >
            <v-img
                class="margin-top-bottom-big round-corner"
                height="300px"
                max-width="500px"
                src="../assets/plate1_400.webp"
                :srcset="`${require('../assets/plate1_900.webp')}, ${require('../assets/plate1_400.webp')} 500w`"
            />
          </v-row>

          <v-row align-content="center" justify="space-around" class="text-h5" >
            Our {{ formatContent() }} service {{formatCityName()?"in "+formatCityName():"" }} is also an excellent option for special occasions such as dinner parties, birthday parties, functions, events, romantic meals for two, or family gatherings. Whether you're looking to add a touch of luxury to your dining experience or simply want to make mealtime easier and more enjoyable, hiring a {{ formatContent() }} is a great option.
          </v-row>

          <v-row align-content="center" justify="space-around"  >
            <v-img
                class="margin-top-bottom-big  round-corner"
                height="300px"
                max-width="500px"
                src="../assets/plate2_400.webp"
                :srcset="`${require('../assets/plate2_900.webp')}, ${require('../assets/plate2_400.webp')} 500w`"
            />
          </v-row>

          <v-row align-content="center" justify="space-around" class="text-h5" >
            {{formatCityName()?"In "+formatCityName()+", our":"Our" }} {{ formatContent() }} service is becoming increasingly popular, as more and more people discover the benefits of having a professional chef prepare meals in the comfort of their own home. Private chefs {{formatCityName()?"in "+formatCityName():"" }} have the opportunity to showcase their skills and creativity, creating delicious and beautifully presented meals that are tailored to their clients' tastes and dietary needs.
          </v-row>

          <v-row align-content="center" justify="space-around"  >
            <v-img
                class="margin-top-bottom-big  round-corner"
                height="300px"
                max-width="500px"
                src="../assets/plate3_400.webp"
                :srcset="`${require('../assets/plate3_900.webp')}, ${require('../assets/plate3_400.webp')} 500w`"
            />
          </v-row>

          <v-row align-content="center" justify="space-around" class="text-h5">
            At Kootto, our {{ formatContent() }}s are also able to work with a wide range of ingredients, including fresh, local produce, exotic spices, and unique flavors. This allows them to create dishes that are both tasty and diverse, and that can help to add some variety and excitement to the client's mealtime experience.
          </v-row>

          <v-row align-content="center" justify="space-around"  >
            <v-img
                class="margin-top-bottom-big  round-corner"
                height="300px"
                max-width="500px"
                src="../assets/plate2_400.webp"
                :srcset="`${require('../assets/plate4_900.webp')}, ${require('../assets/plate4_400.webp')} 500w`"
            />
          </v-row>

          <v-row align-content="center" justify="space-around" class="text-h5">
            At Kootto, our {{ formatContent() }}s are free to create their own menus, this allows our chefs to express themselves and their culinary talents, and create dishes that are truly unique and delicious.
            <br/><br/>

          </v-row>

          <v-row align-content="center" justify="space-around" class="margin-top-bottom-big">
            <v-img
                class=" round-corner"
                height="300px"
                max-width="500px"
                src="../assets/serving_900.webp"
                :srcset="`${require('../assets/serving_900.webp')}, ${require('../assets/serving_400.webp')} 500w`"
            />

          </v-row>

          <v-row align-content="center" justify="center">
            <br/><br/>
            <v-btn color="primary" href="/search" target="" @click.prevent="$router.push({path: `/search`})">See Menus</v-btn>
          </v-row>
        </v-container>

      </div>
    </div>
</template>

<script>

export default {
  name: "PrivateChef",
  components: {
  },
  data: () => ({

  }),
  methods: {
    formatCityName() {
      if (this.$route.params.city!==undefined){
        return this.$route.params.city.charAt(0).toUpperCase() + this.$route.params.city.slice(1);
      }
      return undefined;

    },
    formatContent(firstLetterUppercase) {
      let result = "";
      if (this.$router.currentRoute.path.includes("private-chef")) {
        result = "private chef";
      } else {
        result = "personal chef";
      }
      if (firstLetterUppercase === true) {
        result = result.charAt(0).toUpperCase() + result.slice(1);
      }
      // console.log(result);
      return result;
    },
  }
};
</script>
